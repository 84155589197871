// islands/ServerConfig/ServerConfig.tsx
import Accordion from "../Accordion.tsx";
import ServerConfigHeaderOAuth from "./ServerConfigHeaderOAuth.tsx";
import ServerConfigForm from "./ServerConfigForm.tsx";
import { selectedServerID, allAccordionOpen } from '../globalSignals.ts';

export default function ServerConfig() {
  const serverId = selectedServerID.value;

  return (
    <div class="container mx-auto p-2">
      <Accordion title="Server Configuration" allOpen={allAccordionOpen.value}>
        {serverId ? (
          <>
            <ServerConfigHeaderOAuth serverId={serverId} />
            <ServerConfigForm serverId={serverId} />
          </>
        ) : (
          <p>Select a server to configure</p>
        )}
      </Accordion>
    </div>
  );
}
